import {
	IonFooter,
	IonToolbar,
	IonPage,
	IonContent,
	IonHeader,
} from '@ionic/react';
import { AiTwotoneHome } from 'react-icons/ai';
import { BsPeopleFill } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import Tab1 from './Tab1';
import FriendsPage from './FriendsPage';
import verifyUser from '../data/auth/verifyUser';
import getBars from '../data/getBars';
import getLikes from '../data/getLikes';
import getFriendInfo from '../data/friends/getFriendInfo';
import FetchLikesSWR from '../data/swr/getVotesSWR';
import { home } from 'ionicons/icons';
import { StatusBar, StatusBarStyle, Style } from '@capacitor/status-bar';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import MoveSimple from '../components/images/mooveThree.png';
import cow_img from '../components/images/cow.png';
import cow_simple from '../components/images/pixabayCows/bull-g5e6c2f6af_1280.png';
import scary_cow_simple from '../components/images/pixabayCows/animal-g303d985a5_1280.png';
import drunk_cow from '../components/images/pixabayCows/milk-cow-gd579355f3_1280.png';

const MainPage = ({}) => {
	const [showNavMenu, setShowNavMenu] = useState(false);
	const [homePage, setHomePage] = useState(true);
	const [friendsPage, setFriendsPage] = useState(false);
	const [barData, setBarData] = useState([]);
	const [friends, setFriends] = useState(null);
	const [likes, setLikes] = useState(null); //Depends on our settings. If they can like multiple bars, this will be an array of likes. If they can only like one, this is the like itself.
	const [signedIn, setSignedIn] = useState(null);
	const [didClick, setDidClick] = useState(false);
	const [statusColor, setStatusColor] = useState('#00010E');
	const defaultStatusColor = '#00010E';
	let voteSWRData = FetchLikesSWR();
	// StatusBar.setStyle({ style: 'color:#fffff;' });
	// StatusBar.show();
	const showActions = async () => {
		console.log('action sheet');
		const result = await ActionSheet.showActions({
			title: 'Photo Options',
			message: 'Select an option to perform',
			options: [
				{
					title: 'Upload',
				},
				{
					title: 'Share',
				},
				{
					title: 'Remove',
					style: ActionSheetButtonStyle.Destructive,
				},
			],
		});

		console.log('Action Sheet result:', result);
	};
	useEffect(() => {
		getBars().then((data) => {
			// console.log(data?.data?.data);
			setBarData(data?.data?.data);
			setDidClick(false);
		});
		getLikes().then((data) => {
			setLikes(data?.data?.data);
		});
	}, [didClick]);
	useEffect(() => {
		voteSWRData.then((resp) => {
			// console.log('SWR RES IS', resp?.data);
			resp?.data.sort((a, b) => (a.voted_by <= b.voted_by ? 1 : -1));
			setBarData(resp?.data);
		});
	}, [voteSWRData]);

	useEffect(async () => {
		verifyUser().then((data) => {
			if (data.status === 'error') {
				setSignedIn(false);
				// window.location = '/';
			} else {
				setSignedIn(true);
			}
		});
		getBars().then((data) => {
			console.log(data);
			// console.log(data?.data?.data);
			data?.data?.data.sort((a, b) =>
				a.voted_by <= b.voted_by ? 1 : -1
			);
			setBarData(data?.data?.data);
		});
		getLikes().then((data) => {
			// console.log("User like data", data?.data?.data)
			setLikes(data?.data?.data);
		});
		getFriendInfo('friends').then((data) => {
			setFriends(data?.data?.data);
		});
	}, []);

	return barData && barData[0] ? (
		<>
			<meta
				property="og:image"
				content="https://pushoflove.com/icon.png"
			/>
			<meta
				name="theme-color"
				content={homePage ? statusColor : statusColor}
			/>

			<IonPage>
				<Tab1
					defaultStatusColor={defaultStatusColor}
					setStatusColor={setStatusColor}
					setSignedIn={setSignedIn}
					signedIn={signedIn}
					showActions={showActions}
					barData={barData}
					setBarData={setBarData}
					likes={likes}
					setLikes={setLikes}
					friends={friends}
					setFriends={setFriends}
					shouldShow={!homePage}
					didClick={didClick}
					setDidClick={setDidClick}></Tab1>
				{/* <div className="fixed flex justify-end right-0">
					<div className="w-full flex justify-end text-right">
						<GiHamburgerMenu
							onClick={() => {
								setShowNavMenu(true);
							}}
							className="text-white mr-2 text-4xl"
						/>
					</div>
				</div>

				{showNavMenu ? (
					<div className="absolute w-full h-screen flex flex-col bg-gray-900 bg-opacity-95">
						<div className="w-full flex justify-end -mb-24">
							<AiOutlineClose
								onClick={() => {
									setShowNavMenu(false);
								}}
								className="text-white mr-4 mt-2 text-4xl z-50"
							/>
						</div>
						<div className="flex flex-col my-auto ">
							<div
								onClick={() => {
									setHomePage(true);
								}}
								className="mx-auto my-auto text-4xl text-gray-500">
								Home
							</div>
							<div
								onClick={() => {
									setHomePage(false);
								}}
								className="mx-auto my-auto text-4xl text-gray-500">
								Friends
							</div>
						</div>
					</div>
				) : null} */}

				<FriendsPage
					setStatusColor={setStatusColor}
					shouldShow={homePage}
					signedIn={signedIn}></FriendsPage>
				<div
					className={`fixed top-0 py-1 flex w-full z-50 ${
						homePage ? 'bg-moove-blue' : 'bg-moove-blue'
					}`}>
					<div className="w-full">
						<img className="h-8 ml-12 z-50" src={MoveSimple}></img>
					</div>
					<div className="w-full">
						<img
							className="h-8 ml-12 z-50"
							src={drunk_cow}
							style={{ filter: 'brightness(250%)' }}></img>
					</div>
					<div className="w-full flex justify-end">
						<AiTwotoneHome
							onClick={() => {
								console.log('CHANGING PAGE');
								setFriendsPage(false);
								setHomePage(true);
							}}
							className={`mx-auto text-2xl w-full rounded-lg ${
								!homePage ? 'text-gray-400' : 'text-white'
							}`}
						/>
						<BsPeopleFill
							onClick={() => {
								console.log('CHANGING PAGE');
								setHomePage(false);
								setFriendsPage(true);
							}}
							className={` mx-auto text-2xl w-full rounded-lg ${
								!friendsPage ? 'text-gray-400' : 'text-white'
							}`}
						/>
					</div>
				</div>
				{/* <IonFooter
					color="step-600"
					className={homePage ? 'bg-gray-900' : 'bg-white'}>
					<IonToolbar color="step-600">
						<div className="flex text-center w-full rounded-lg bg-gray-900 bg-opacity-0 opacity-100 mx-auto border border-opacity-0">
							<AiTwotoneHome
								onClick={() => {
									console.log('CHANGING PAGE');
									setFriendsPage(false);
									setHomePage(true);
								}}
								className={`mx-auto text-2xl w-full rounded-lg ${
									!homePage
										? 'text-gray-400'
										: 'text-white bg-gray-400'
								}`}
							/>

							<BsPeopleFill
								onClick={() => {
									console.log('CHANGING PAGE');
									setHomePage(false);
									setFriendsPage(true);
								}}
								className={` mx-auto text-2xl w-full rounded-lg ${
									!friendsPage
										? 'text-gray-400'
										: 'text-white bg-gray-400'
								}`}
							/>
						</div>
					</IonToolbar>
				</IonFooter> */}
			</IonPage>
		</>
	) : null;
};
export default MainPage;
