import fetch from 'unfetch';
/**
 *
 * @param {*} unfriendId the id of the person to unfriend //TO DO: convert to allow ph + usernames too
 * @returns success or error
 */
const unfriend = async (unfriendId) => {
	const url = `/api/friends/unfriend`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ unfriendId: unfriendId }),
	});

	if (res.ok) {
		return { status: 'succ', data: await res.json() };
	} else {
		return { status: 'error' };
	}
};
export default unfriend;
