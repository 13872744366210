import fetch from 'unfetch';
import bcrypt from 'bcryptjs'
/**
 *
 * @param {*} email
 * @param {*} firstName
 * @param {*} lastName
 * @param {*} password
 * @returns
 */
const signup = async (email, firstName, lastName, password, yr) => {
	console.log(yr);
	const url = `/api/signup`;
	const emailWEnding = email.toLowerCase() + '@wustl.edu';
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			email: emailWEnding,
			firstName: firstName,
			lastName: lastName,
			password: bcrypt.hashSync(password, bcrypt.genSaltSync()),
			yr:yr
		}),
	});

	if (res.ok) {
		return { status: 'success', data: await res.json() };
	} else {
		let r = await res.json()
		console.log(r)
		return { status: 'error', data: await res.json() };
	}
};
export default signup;