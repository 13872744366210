import { IonBackButton, IonContent, IonHeader, IonToolbar } from '@ionic/react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FiShare } from 'react-icons/fi';
import { MdArrowBackIos, MdArrowDownward, MdShare } from 'react-icons/md';
import { PieChart, Pie, Legend, ResponsiveContainer } from 'recharts';
import { GiBigEgg, GiChicken } from 'react-icons/gi';
import { useEffect, useState } from 'react';
import getGradeDist from '../data/getGradeDist';
import { shareOutline } from 'ionicons/icons';
import ShareBar from './share/ShareBar';
import { isMobile } from 'react-device-detect';
import {
	HiOutlineClipboardCopy,
	HiOutlineClipboardCheck,
} from 'react-icons/hi';
import BarFriendTag from './friends/BarFriendTag';

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}
const BarPopUp = ({
	barData,
	setBarPopUp,
	friends,
	signedIn,
	setStatusColor,
}) => {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()?.height / 60
	);
	const [windowDimensionsChart, setWindowDimensionsChart] = useState(
		getWindowDimensions()?.height / 60
	);

	const [distData, setDistData] = useState(null);
	const [freshDist, setFreshDist] = useState(0);
	const [sophDist, setSophDist] = useState(0);
	const [junDist, setJunDist] = useState(0);
	const [senDist, setSenDist] = useState(0);
	const [showCopyPop, setShowCopyPop] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	// console.log(id)
	// console.log(isLiked)
	useEffect(() => {
		setStatusColor('#CFE2F3');
		console.log('BAR DATA IS ', barData);
		console.log(isMobile);
		if (barData?.freshDist !== undefined) {
			console.log('TEHRE IS EXISTING DATA');
			if (
				barData?.freshDist +
					barData?.sophDist +
					barData?.junDist +
					barData?.senDist ===
					0 &&
				barData?.likes !== 0
			) {
				getGradeDist(barData?.id).then((data) => {
					setDistData(data?.data);
				});
			} else {
				setFreshDist(barData?.freshDist);
				setSophDist(barData?.sophDist);
				setJunDist(barData?.junDist);
				setSenDist(barData?.senDist);
			}
		} else {
			console.log('NO EXIST DATA');
			getGradeDist(barData?.id).then((data) => {
				setDistData(data?.data);
			});
		}
	}, []);

	// const shouldAnimate = ()=>{
	// 	if(count = 0){return ("hover:animate-ping")

	// }else {return ""}
	// }

	useEffect(() => {
		if (distData) {
			let gradeCounts = {
				twentyTwo: 0,
				twentyThree: 0,
				twentyFour: 0,
				twentyFive: 0,
				other: 0,
			};

			for (let i in distData) {
				if (distData[i].yr === '2022') {
					gradeCounts.twentyTwo = gradeCounts.twentyTwo + 1;
				} else if (distData[i].yr === '2023') {
					gradeCounts.twentyThree = gradeCounts.twentyThree + 1;
				} else if (distData[i].yr === '2024') {
					gradeCounts.twentyFour = gradeCounts.twentyFour + 1;
				} else if (distData[i].yr === '2025') {
					gradeCounts.twentyFive = gradeCounts.twentyFive + 1;
				} else {
					gradeCounts.other = gradeCounts.other + 1;
				}
			}
			setSenDist((gradeCounts.twentyTwo / distData.length) * 100);
			setJunDist((gradeCounts.twentyThree / distData.length) * 100);
			setSophDist((gradeCounts.twentyFour / distData.length) * 100);
			setFreshDist((gradeCounts.twentyFive / distData.length) * 100);
		}
	}, [distData]);

	const countFriends = () => {
		let c = [];
		for (let i in friends) {
			for (let j in barData?.friendsInAttendance) {
				if (barData?.friendsInAttendance[j] === friends[i]?.email) {
					c.push(friends[i]);
				}
			}
		}
		return c;
	};

	const chartData = [
		{ name: 'Freshmen', students: freshDist, fill: '#4CE0DB' },
		{ name: 'Sophomores', students: sophDist, fill: '#15948F' },
		{ name: 'Juniors', students: junDist, fill: '#D57C4E' },
		{ name: 'Seniors', students: senDist, fill: '#FFA270' },
	];

	const summaryChartData = [
		{
			name: 'Underclassmen',
			students: freshDist + sophDist,
			fill: '#49A6A3',
		},
		{
			name: 'Upperclassmen',
			students: junDist + senDist,
			fill: '#EA8F5F',
		},
	];

	// NOTE: Alternative color scheme. Also useful for testing with hardcoded values

	// const chartData = [
	// 	{ name: 'Freshmen', students: 5, fill: '#4CE0DB' },
	// 	{ name: 'Sophomores', students: 10, fill: '#15948F' },
	// 	{ name: 'Juniors', students: 15, fill: '#7D2B94' },
	// 	{ name: 'Seniors', students: 70, fill: '#CC84E0' },
	// ];
	// const summaryChartData = [
	// 	{ name: 'Underclassmen', students: 15, fill: '#49A6A3' },
	// 	{ name: 'Upperclassmen', students: 85, fill: '#9762A6' },
	// ];

	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
		name,
		value,
	}) => {
		const RADIAN = Math.PI / 180;
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		console.log(name);
		if (name == 'Underclassmen' && value >= 10) {
			return (
				<GiBigEgg
					x={x}
					y={y - 10}
					fill="white"
					dominantBaseline="central"></GiBigEgg>
			);
		} else if (name == 'Upperclassmen' && value >= 10) {
			return (
				<GiChicken
					x={x}
					y={y - 6}
					fill="white"
					dominantBaseline="central"></GiChicken>
			);
		}
		return;
	};

	const renderActiveShape = (props) => {
		const RADIAN = Math.PI / 180;
		const { cx, name, cy, midAngle, outerRadius, innerRadius, percent } =
			props;
		if (percent == 0) {
			return;
		}
		const lineLength = 20;
		const horzLineLengthFctr = 9 * name.length;

		const sin = Math.sin(-RADIAN * midAngle);
		const sin1 = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const cos2 = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius - (outerRadius - innerRadius) / 2) * cos;
		const sy = cy + (outerRadius - (outerRadius - innerRadius) / 2) * sin;
		const mx = cx + (outerRadius + lineLength) * cos2;
		const my = cy + (outerRadius + lineLength) * sin1;
		const exx = mx + (cos >= 0 ? 1 : -1) * horzLineLengthFctr;
		const ex = mx + (cos >= 0 ? 1 : -1) * 5;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';
		return (
			<g>
				<circle cx={sx} cy={sy} r={2} stroke="none" />
				<path
					d={`M${sx},${sy}L${mx},${my}L${exx},${ey}`}
					stroke="#B5C1CC"
					fill="none"
				/>
				<text
					x={ex}
					y={ey - 5}
					textAnchor={textAnchor}
					fill="#4191DD">{`${(percent * 100).toFixed()}%`}</text>
				<text
					fontSize={windowDimensions}
					x={ex}
					y={ey}
					dy={17}
					textAnchor={textAnchor}
					fill="#181818">
					{name}
				</text>
			</g>
		);
	};

	const customLabelLines = (props) => {
		const { cx, name, cy, midAngle, outerRadius, innerRadius, percent } =
			props;
		if (percent == 0) {
			return false;
		}
		return true;
	};

	const renderChartLabel = (entry) => {
		return entry.name;
	};
	// const getYearCounts = () => {
	// 	let c = [];
	// 	for (let i in friends) {
	// 		for (let j in barData?.likes) {
	// 			if (barData?.likes[j] === friends[i]?.email) {
	// 				c.push(friends[i]);
	// 			}
	// 		}
	// 	}
	// 	return c;
	// };

	const p = async () => {
		await navigator.share({
			title: 'The Moove',
			text: 'Vote for this move!',
			url: `https://the-moove.com/home?bar=${barData?.id}`,
			dialogTitle: 'Share with buddies',
		});
	};
	return (
		<>
			<IonHeader>
				<div className="w-full flex mt-4 h-8">
					{/* <div>
						<MdArrowDownward
							onClick={() => {
								setBarPopUp(false);
							}}
							className="text-sky-500 ml-5 text-4xl fixed bg-white rounded-lg"></MdArrowDownward>
					</div> */}
					<div className="w-1/3 bg-gray-400 rounded-lg h-1 mx-auto"></div>
				</div>
				<div className="text-gray-300 text-center text-xs -mt-4">
					Swipe To Close{' '}
				</div>
			</IonHeader>
			<IonContent>
				{showCopyPop ? (
					<ShareBar
						id={barData?.id}
						setShowShareBar={setShowCopyPop}></ShareBar>
				) : null}
				<div className="z-40 w-full bg-opacity-0 flex flex-col overflow-scroll-y pb-24">
					{/* <img
					src={barData?.image}
					className="border-b-2 border-black"></img> */}
					<div className=" mt-4 flex flex-col">
						<div className="mx-auto text-black text-4xl text-center font-medium font-mono w-2/3">
							{barData?.name}
						</div>
						<div className="mx-auto mt-4 text-center text-lg text-black font-light w-3/4">
							There are currently{' '}
							<span className="font-semibold">
								{barData?.likes !== undefined
									? barData?.likes
									: barData?.voted_by}
							</span>{' '}
							people going!
						</div>
						<div className="-mt-6 w-full h-full relative">
							{/* <ResponsiveContainer width={'100%'} height={'100%'}> */}
							<PieChart
								height={385}
								width={385}
								margin={{
									top: 5,
									right: 15,
									left: 15,
									bottom: 5,
								}}>
								<Pie
									data={summaryChartData}
									dataKey="students"
									nameKey="name"
									fill="green"
									cx="50%"
									cy="50%"
									width={10}
									outerRadius={45}
									label={renderCustomizedLabel}
									labelLine={false}
									legendType="none"
								/>
								<Pie
									options={{
										responsive: true,
										maintainAspectRatio: true,
									}}
									data={chartData}
									dataKey="students"
									nameKey="name"
									fill="green"
									cx="50%"
									cy="50%"
									innerRadius={55}
									outerRadius={75}
									label={renderActiveShape}
									labelLine={customLabelLines}
								/>
								{/*label={renderChartLabel}*/}
								{/* <Legend/> */}
							</PieChart>
							{/* </ResponsiveContainer> */}
						</div>
						<div className="-mt-14 flex flex-col h-1/3 ">
							<span className="ml-8 text-black font-medium">
								Friends Attending
							</span>{' '}
							{!signedIn ? (
								<div className="text-center">
									Sign in to see this!
								</div>
							) : (
								<div className="flex flex-col h-full">
									{countFriends().map((data, id) => {
										let min = Math.ceil(0);
										let max = Math.floor(6);
										let num =
											Math.floor(
												Math.random() * (max - min + 1)
											) + min;

										return (
											<BarFriendTag
												data={data}></BarFriendTag>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
			</IonContent>
			<div className="flex absolute w-full text-black bottom-4 text-center">
				<div className="border-blue-200 border rounded-lg flex mx-auto p-2 bg-gray-200 z-50 bg-opacity-100">
					<div className=" my-auto z-50">Share This Bar</div>
					{isMobile ? (
						<FiShare
							className="text-sky-700 text-2xl z-50 ml-2"
							onClick={() => {
								p();
							}}></FiShare>
					) : !isCopied ? ( // can maybe use navigator.clipboard.readtext to check if already copied
						<HiOutlineClipboardCopy
							className="text-sky-700 text-2xl z-50 ml-2"
							onClick={() => {
								navigator.clipboard.writeText(
									'Come checkout the moove! https://www.the-moove.com/'
								);
								setIsCopied(true);
							}}></HiOutlineClipboardCopy>
					) : (
						<HiOutlineClipboardCheck
							className="text-sky-700 text-2xl z-50 ml-2"
							onClick={() => {
								navigator.clipboard.writeText(
									'Come checkout the moove! https://www.the-moove.com/'
								);
							}}></HiOutlineClipboardCheck>
					)}
				</div>
			</div>
		</>
	);
};
export default BarPopUp;
