import { IonActionSheet } from '@ionic/react';
import { close, trash } from 'ionicons/icons';
import { useState } from 'react';
import { ImSpinner } from 'react-icons/im';
import getFriendInfo from '../../data/friends/getFriendInfo';
import unfriend from '../../data/friends/unfriend';

const FriendTag = ({
	setExistFriendData,
	setExistFriendModal,
	data,
	setExistFriend,
	colors,
	num,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	return (
		<div className="w-full flex mt-1">
			<div
				onClick={() => {
					setExistFriendData(data);
					setExistFriendModal(true);
				}}
				className={`rounded-lg py-2 px-4 text-sm flex  w-full font-medium`}>
				<div
					className={`${colors[num]} flex w-12 h-12 rounded-full my-auto `}>
					<div className="my-auto mx-auto">
						{data?.firstName[0]}
						{data?.lastName[0]}
					</div>
				</div>{' '}
				<div className="flex flex-col ml-6">
					<div className="text-md font-bold ">
						{data?.firstName} {data?.lastName}
					</div>
					<div className="my-auto font-light">{data?.email}</div>{' '}
				</div>
			</div>
			{!isLoading ? (
				<div
					className=" border border-black px-2 py-1 my-auto text-sm rounded-md hover:bg-gray-400"
					onClick={() => {
						// setIsLoading(true);
						setShowWarning(true);
					}}>
					Unfriend
				</div>
			) : (
				<div className="flex">
					<ImSpinner className="my-auto mx-auto text-lg text-blue-400 animate-spin"></ImSpinner>
				</div>
			)}
			{/* {data?.bar_vote !== 0 ? (
												<div className="text-black">
													Currently going out to{' '}
												</div>
											) : null} */}
			<IonActionSheet
				isOpen={showWarning}
				onDidDismiss={() => setShowWarning(false)}
				buttons={[
					{
						text: 'Unfriend?',
						role: 'destructive',
						icon: trash,
						id: 'delete-button',
						data: {
							type: 'delete',
						},
						handler: () => {
							unfriend(data?.id).then((data) => {
								getFriendInfo('friends').then((data) => {
									setExistFriend(data?.data?.data);
								});
								setIsLoading(false);
							});
						},
					},
					{
						text: 'Cancel',
						icon: close,
						role: 'cancel',
						handler: () => {
							console.log('Cancel clicked');
						},
					},
				]}></IonActionSheet>
		</div>
	);
};
export default FriendTag;
