import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import reAuthorize from './reAuthorize';
const verifyUser = async (type) => {
	const url = `/api/verifyuser`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
	});

	if (res.ok) {
		return { status: 'success' };
	} else {
		let errorData = await res.json();
		if (errorData?.code === 1) {
			let d = await reAuthorize();

			if (d?.status !== 'error') {
				console.log('SUCCSESSFULLY REAUTHORIZED');
				let r = await verifyUser();
				return r;
			} else {
				console.log('ERROR REAUTHORIZED');
				return { status: 'error' };
			}
		}
		console.log('ERROR REAUTHORIZED');
		return { status: 'error' };
	}
};
export default verifyUser;
