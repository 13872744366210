const BarFriendTag = ({ data }) => {
	const colors = [
		'bg-red-400',
		'bg-blue-400',
		'bg-indigo-400',
		'bg-yellow-500',
		'bg-green-400',
		'bg-fuchsia-400',
		'bg-rose-400',
	];
	let min = Math.ceil(0);
	let max = Math.floor(6);
	let num = Math.floor(Math.random() * (max - min + 1)) + min;

	return (
		<div className="w-10/12 mx-auto flex mt-2 z-30">
			<div
				className={`w-12 h-12 ${colors[num]} rounded-full text-center flex`}>
				<span className="my-auto mx-auto text-md">
					{data?.firstName[0]}.{data?.lastName[0]}.
				</span>
			</div>{' '}
			<div></div>
			<div className="my-auto text-left">
				<span className="font-light text-black ml-4 text-md">
					{data?.firstName} {data?.lastName}
				</span>
			</div>
		</div>
	);
};
export default BarFriendTag;
