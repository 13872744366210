import {
	IonContent,
	IonFooter,
	IonPage,
	IonTabBar,
	IonTab,
	IonLabel,
	IonIcon,
	IonTabButton,
	IonTabs,
	IonRouterOutlet,
	IonSpinner,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/react';
import {
	calendar,
	closeCircleOutline,
	home,
	informationCircle,
	map,
	person,
} from 'ionicons/icons';
// import { StatusBar, Style } from '@capacitor/status-bar';
import { useEffect, useState, useRef } from 'react';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import searchUsers from '../data/friends/searchUsers';
import sendFriendRequest from '../data/friends/sendFriendRequest';
import acceptOrDecline from '../data/friends/acceptOrDecline';
import getFriendInfo from '../data/friends/getFriendInfo';
import { ImSpinner } from 'react-icons/im';
import verifyUser from '../data/auth/verifyUser';
import { MdCircle, MdSearch } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import fromBarId from '../data/bars/fromBarId';
import move from '../components/images/moove.png';
import ExistFriendTag from '../components/friends/FriendPopUp';
import {
	HiOutlineClipboardCopy,
	HiOutlineClipboardCheck,
} from 'react-icons/hi';
import { isMobile } from 'react-device-detect';
import { FiShare } from 'react-icons/fi';
import unfriend from '../data/friends/unfriend';
import FriendPopUp from '../components/friends/FriendPopUp';
import FriendTag from '../components/friends/FriendTag';

const FriendsPage = ({ shouldShow, signedIn }) => {
	const [searchResp, setSearchResp] = useState(null);
	const [showFriendModal, setShowFriendModal] = useState(false);
	const [friendModalData, setFriendModalData] = useState(null);
	const [isLoadingReq, setIsLoadingReq] = useState(false);
	const [friendReq, setFriendReq] = useState(null);
	const [existFriend, setExistFriend] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingSearchResp, setLoadingSearchResp] = useState(false);
	const [existFriendModal, setExistFriendModal] = useState(false);
	const [existFriendData, setExistFriendData] = useState(null);
	const [isCopied, setIsCopied] = useState(false);
	const [alreadyFriends, setAlreadyFriends] = useState(false);
	let searchRef = useRef(null);

	// let friendSWRData = FetchFriendInfoSWR('pending_requests');

	const colors = [
		'bg-red-300',
		'bg-blue-300',
		'bg-indigo-300',
		'bg-yellow-300',
		'bg-green-300',
		'bg-fuchsia-300',
		'bg-rose-300',
	];
	useEffect(() => {
		verifyUser().then((data) => {
			if (data?.status !== 'error') {
			} else {
				// window.location = '/';
			}
		});
	}, []);
	// let existFriendData = FetchExistFriendSWR('friends')
	useEffect(() => {
		if (
			searchRef?.current?.value === '' ||
			searchRef?.current?.value === ' ' ||
			searchRef?.current?.value === null ||
			searchRef?.current?.value === undefined
		) {
			setSearchResp(null);
		}
	}, [searchRef?.current?.value]);
	useEffect(() => {
		getFriendInfo('friends').then((data) => {
			setExistFriend(data?.data?.data);
		});
		getFriendInfo('pending_requests').then((data) => {
			setFriendReq(data?.data?.data);
		});
		// verifyUser();
	}, []);
	useEffect(() => {
		getFriendInfo('friends').then((data) => {
			setExistFriend(data?.data?.data);
		});
	}, [friendReq]);
	const p = async () => {
		await navigator.share({
			title: 'The Moove',
			text: 'Where are you headed? ',
			url: 'https://the-moove.com/home',
			dialogTitle: 'Share with buddies',
		});
	};
	const checkIfPendReq = () => {
		if (friendReq) {
			for (let i in friendReq) {
				if (friendModalData?.id === friendReq[i]?.id) {
					return true;
				}
			}
			return false;
		} else {
			return false;
		}
	};
	useEffect(() => {
		if (searchRef?.current?.value === '') {
			setSearchResp(null);
			setLoadingSearchResp(false);
		}
	}, [searchRef?.current?.value]);

	const convertBarId = async (barId) => {
		let d = await fromBarId(barId);
		return d;
	};

	const doRefresh = async (event) => {
		getFriendInfo('pending_requests').then((data) => {
			setFriendReq(data?.data?.data);
			event.detail.complete();
		});

		setTimeout(() => {}, 1500);
	};
	return (
		// <IonPage>
		<IonContent hidden={shouldShow} scrollY={true} fullscreen={true}>
			<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
				<IonRefresherContent
					refreshingSpinner={'crescent'}></IonRefresherContent>
				{/* // pullingIcon={chevronDownCircleOutline}
						// pullingText="Pull to refresh"
						// refreshingSpinner="circles"
						// refreshingText="Refreshing..." */}
			</IonRefresher>
			{/* <img src={move} className="w-1/3 mx-auto mt-8"></img> */}
			<div
				className=" flex flex-col mt-12"
				onClick={() => {
					setSearchResp(null);
				}}>
				<div className="mx-auto w-full flex flex-col relative">
					<div className="flex flex-rows w-3/4 mx-auto mt-8 bg-gray-200 rounded-lg">
						<MdSearch className="my-auto mx-auto text-gray-400"></MdSearch>
						<input
							className={
								searchResp
									? 'w-11/12 py-2 rounded-none bg-gray-800 bg-opacity-0 mx-auto outline-none '
									: 'w-2/3 py-2 rounded-none bg-gray-800 bg-opacity-0 mx-auto outline-none'
							}
							placeholder={'Search for a friend...'}
							ref={searchRef}
							onChange={() => {
								if (
									searchRef?.current?.value !== '' &&
									searchRef?.current?.value !== ''
								) {
									setLoadingSearchResp(true);
									searchUsers(searchRef.current.value).then(
										(data) => {
											if (data?.data[0] === undefined) {
												setLoadingSearchResp(false);
												setSearchResp(null);
											} else {
												setLoadingSearchResp(false);
												setSearchResp(data?.data);
											}
										}
									);
								} else {
									setLoadingSearchResp(false);
									setSearchResp(null);
								}
							}}></input>
					</div>
					<div
						className={
							searchResp || loadingSearchResp
								? 'flex flex-col mx-auto w-full h-screen absolute top-20 bg-white z-30'
								: 'flex flex-col mx-auto w-full absolute top-12 bg-white'
						}>
						{searchResp ? (
							<>
								{loadingSearchResp ? (
									<div className="w-full h-full flex Z-50">
										<FaSpinner className="text-gray-900 animate-spin z-50 mx-auto mt-48 text-4xl"></FaSpinner>
									</div>
								) : (
									<>
										{searchResp?.map((data, id) => {
											if (id < 4) {
												return (
													<div
														key={id}
														onClick={() => {
															setSearchResp(null);

															for (let i in existFriendData) {
																if (
																	existFriendData[
																		i
																	].id ===
																	data?.id
																) {
																	setAlreadyFriends(
																		true
																	);
																}
															}
															setFriendModalData(
																data
															);
															setShowFriendModal(
																true
															);
														}}
														className="p-2 border-b border-gray-400 bg-gray-300 w-4/5 text-left mx-auto rounded-sm  shadow-gray-400 z-40">
														{data?.firstName}{' '}
														{data?.lastName}
													</div>
												);
											} else {
												return null;
											}
										})}
									</>
								)}
							</>
						) : null}
					</div>
					{showFriendModal ? (
						<div className="w-full h-screen absolute flex z-50">
							<div className="sticky w-2/3 h-1/4 mx-auto mt-28 bg-gray-300 rounded-lg flex flex-col shadow-2xl shadow-black">
								<span
									className="mt-2 ml-4 text-lg"
									onClick={() => {
										setAlreadyFriends(false);
										setIsLoadingReq(false);
										setShowFriendModal(false);
									}}>
									X
								</span>

								<div className="mx-auto mt-4 text-2xl">
									{friendModalData?.firstName}{' '}
									{friendModalData?.lastName}
								</div>

								<div className="mx-auto text-sm mt-4 font-light">
									{friendModalData?.email}
								</div>
								{!isLoadingReq ? (
									<>
										<button
											className="mt-4 w-2/3 mx-auto bg-blue-400 rounded-lg p-2 hover:bg-blue-500"
											onClick={() => {
												sendFriendRequest(
													friendModalData?.id
												).then((data) => {});
												setIsLoadingReq(true);
											}}>
											Friend
										</button>
									</>
								) : (
									<button
										className="mt-4 w-2/3 mx-auto bg-blue-400 rounded-lg p-2"
										onClick={() => {}}>
										Sent!
									</button>
								)}
							</div>
						</div>
					) : null}
					<div className=" w-7/8 mx-auto mt-12 h-1/3 overflow-y-scroll font-light">
						{/* <div className="bg-gray-100 rounded-lg px-2">
							Friend Requests (pull to refresh):
						</div> */}
						{friendReq ? (
							<>
								{friendReq?.map((data, idx) => {
									return (
										<div className="w-full flex" key={idx}>
											<div className="rounded-lg py-2 px-4 text-sm flex flex-col font-medium">
												<div className="w-3/4 text-left my-auto">
													{data?.firstName}{' '}
													{data?.lastName}
												</div>{' '}
												<div className="text-left my-auto text-xs font-light">
													{data?.email}
												</div>{' '}
											</div>

											<div className="flex my-auto mt-2">
												{!isLoading ? (
													<>
														<div
															className="bg-blue-400 text-white py-1 px-2 rounded-lg text-md mx-auto my-auto"
															onClick={() => {
																setIsLoading(
																	true
																);
																acceptOrDecline(
																	data?.id,
																	true
																).then(
																	(data) => {
																		getFriendInfo(
																			'pending_requests'
																		).then(
																			(
																				data
																			) => {
																				setFriendReq(
																					data
																						?.data
																						?.data
																				);
																			}
																		);
																		getFriendInfo(
																			'friends'
																		).then(
																			(
																				data
																			) => {
																				setExistFriend(
																					data
																						?.data
																						?.data
																				);
																			}
																		);
																		setIsLoading(
																			false
																		);
																	}
																);
															}}>
															Confirm
														</div>
														<div
															className="border border-black py-1 px-2 text-md mx-auto my-auto rounded-lg ml-2"
															onClick={() => {
																setIsLoading(
																	true
																);
																acceptOrDecline(
																	data?.id,
																	false
																).then(
																	(data) => {
																		setIsLoading(
																			false
																		);
																		getFriendInfo(
																			'friends'
																		).then(
																			(
																				data
																			) => {
																				setExistFriend(
																					data
																						?.data
																						?.data
																				);
																				getFriendInfo(
																					'pending_requests'
																				).then(
																					(
																						data
																					) => {
																						setFriendReq(
																							data
																								?.data
																								?.data
																						);
																					}
																				);
																			}
																		);
																		setIsLoading(
																			false
																		);
																	}
																);
															}}>
															Deny
														</div>
													</>
												) : (
													<ImSpinner className="mx-auto text-blue-500 text-xl animate-spin"></ImSpinner>
												)}
											</div>
										</div>
									);
								})}
							</>
						) : null}
					</div>
					{existFriendModal ? (
						<FriendPopUp
							isLoadingReq={isLoadingReq}
							setIsLoadingReq={setIsLoadingReq}
							friendModalData={existFriendData}
							setShowExistFriendModal={setExistFriendModal}
							setExistFriend={setExistFriend}></FriendPopUp>
					) : null}

					<div className="w-7/8 mx-auto mt-24 h-1/3 overflow-y-scroll">
						<div>Friends</div>
						{existFriend ? (
							<>
								{existFriend
									?.sort((a, b) =>
										a.lastName <= b.lastName ? -1 : 1
									)
									.map((data, id) => {
										let min = Math.ceil(0);
										let max = Math.floor(6);
										let num =
											Math.floor(
												Math.random() * (max - min + 1)
											) + min;

										return (
											//${colors[num]}
											<FriendTag
												setExistFriendData={
													setExistFriendData
												}
												setExistFriendModal={
													setExistFriendModal
												}
												num={num}
												data={data}
												colors={colors}
												setExistFriend={
													setExistFriend
												}></FriendTag>
										);
									})}
							</>
						) : null}
					</div>
					<div className=" w-full mx-auto mt-24 h-1/3 overflow-y-scroll text-center flex flex-col">
						<div>Invite to vote:</div>
						<div className="flex z-20 text-center mx-auto text-xl font-light">
							Click here to invite friends
							{isMobile ? (
								<FiShare
									className="my-auto"
									onClick={() => {
										p();
									}}></FiShare>
							) : !isCopied ? ( // can maybe use navigator.clipboard.readtext to check if already copied
								<HiOutlineClipboardCopy
									className="my-auto"
									onClick={() => {
										navigator.clipboard.writeText(
											'Come checkout the moove! https://www.the-moove.com/'
										);
										setIsCopied(true);
									}}></HiOutlineClipboardCopy>
							) : (
								<HiOutlineClipboardCheck
									className="my-auto"
									onClick={() => {
										navigator.clipboard.writeText(
											'Come checkout the moove! https://www.the-moove.com/'
										);
									}}></HiOutlineClipboardCheck>
							)}
						</div>
					</div>
				</div>
			</div>
		</IonContent>
		// 	{/* <Footer></Footer>
		// </IonPage> */}
	);
};

export default FriendsPage;
