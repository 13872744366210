import { useEffect, useState } from 'react';

import fetch from 'unfetch';
/**
 *
 * @param {*} email The users email
 * @param {*} password The users password
 * @returns status:"success" || status:"error"
 */
const login = async (email, password) => {
	const url = `/api/login`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			email: email,
			password: password,
		}),
	});

	if (res.ok) {
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		let p = responseData?.data;
		console.log(p);

		localStorage.setItem('auth', responseData.jwt);
		localStorage.setItem('userData', JSON.stringify(p));

		return { status: 'success' };
	} else {
		let responseData = JSON.parse(JSON.stringify(await res.json()));

		console.log(responseData);
		return { status: 'error', code: responseData?.code };
	}
};
export default login;
