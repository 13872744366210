import { IonContent, IonPage, IonToast } from '@ionic/react';
import { useRef, useState } from 'react';
import login from '../data/login';
const LoginPage = () => {
	const [loginError, setLoginError] = useState(false);
	const [loginMessageError, setLoginMessageError] = useState('Error.');
	let emailRef = useRef();
	let pwRef = useRef();

	return (
		<IonPage>
			<IonContent>
				<>
					<IonToast
						isOpen={loginError}
						onDidDismiss={() => setLoginError(false)}
						message={loginMessageError}
						position={'top'}
						duration={3000}
					/>
					<div className="bg-white h-screen">
						<div className="w-full flex flex-col text-gray-800">
							<div className="mx-auto mt-24 font-medium w-4/5 text-4xl text-gray-400 text-center">
								It's already that time of the week again?
								<div className="text-sm pt-4 font-light">
									Enter your login information :)
								</div>
							</div>
							<div className="mx-auto w-2/3">
								<input
									ref={emailRef}
									type="email"
									className="w-1/2 mx-auto py-2 px-4 bg-opacity-0 text-black bg-gray-100 mt-24 border-b-2 border-blue-200 text-gray-800 outline-none"></input>
								<span className="w-1/2 mx-auto">
									@wustl.edu
								</span>
							</div>
							<input
								ref={pwRef}
								type="password"
								placeholder="Password"
								className="w-2/3 mx-auto py-2 px-4 bg-opacity-0  bg-gray-100 mt-12 border-b-2 border-blue-200 text-gray-800 outline-none"></input>
							<button
								className="text-gray-200 mx-auto bg-blue-500 py-2 px-4 rounded-lg mt-12"
								onClick={() => {
									login(
										emailRef?.current?.value?.toLowerCase() +
											'@wustl.edu',
										pwRef?.current?.value
									).then((data) => {
										console.log(data);
										if (data?.status === 'success') {
											window.location = '/home';
										} else {
											if (data.code === 2) {
												setLoginMessageError(
													'Error, invalid credentials. Try again.'
												);
												setLoginError(true);
											} else {
												setLoginMessageError(
													"Error — unconfirmed email. Visit '/verify' to confirm!"
												);
												setLoginError(true);
											}
										}
									});
								}}>
								Submit
							</button>
						</div>
					</div>
				</>
			</IonContent>
		</IonPage>
	);
};
export default LoginPage;
