import fetch from 'unfetch';
/**
 *
 * @param {*} uuid The UUID of the user to be friends with or not. This is NOT the UUID of the current user. It is the ID of the person who requested you!
 * @param {*} decision Boolean (true or false) of whether or not to accept. True to accept, false to decline. !!CANNOT BE A STRINg!!
 * @returns succ for good error for bad
 */
const acceptOrDecline = async (uuid, decision) => {
	const url = `/api/friends/handleReq`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ uuid: uuid, decision: decision }),
	});

	if (res.ok) {
		return { status: 'succ', data: await res.json() };
	} else {
		return { status: 'error' };
	}
};
export default acceptOrDecline;
