import { useEffect, useState } from 'react';

import fetch from 'unfetch';

const fromBarId = async (barId) => {
	const url = `/api/bars/getById`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			barId: barId,
		}),
	});

	if (res.ok) {
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		let p = responseData?.data;
		console.log(p);

		return p?.name;
	} else {
		let responseData = JSON.parse(JSON.stringify(await res.json()));

		console.log(responseData);
		return { status: 'error' };
	}
};
export default fromBarId;
