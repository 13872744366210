import { IonContent, IonHeader } from '@ionic/react';

const WhyOtto = ({ setWhyOtto }) => {
	return (
		<>
			<IonHeader color="">
				<div className="w-4/5 pt-8 flex mb-4">
					<div className="w-3/4 mx-auto z-50">
						<span
							onClick={() => {
								setWhyOtto(false);
							}}
							className="bg-blue-400 text-white rounded-lg py-2 px-2 mx-auto">
							Close
						</span>
					</div>
				</div>
			</IonHeader>
			<IonContent color="white">
				<div className="text-black w-full flex flex-col pb-12">
					<div className="w-4/5 text-3xl font-bold text-center mx-auto mt-12">
						It's about bringing the <span>power</span> back to the
						students.{' '}
					</div>
					<div className="font-light text-left w-4/5 mx-auto pt-4">
						&nbsp; &nbsp; Here's the truth — paying for tickets
						sucks. And its kind of crazy. Bars depend on{' '}
						<span className="font-bold">us</span>, not the other way
						around. Why do we pay for tickets and marked up drinks,
						when bars should be clawing for our business....{' '}
						<span className="text-black font-semibold">
							What happened to 2 dollar drinks on Thursdays?
						</span>
					</div>
					<div className="font-light text-left w-4/5 mx-auto mt-4">
						&nbsp; &nbsp; If we can collectivily pick our moves, we
						can leverage that power to force venues to offer
						competitive prices and entrances for our business.
						Instead of paying middlemen to rent out and oversell
						venues that we would have gone to ANYWAYS (and used to
						never pay for), we can decide our night's path.<br></br>{' '}
						<br></br>It's time to retake our college social life.
					</div>
				</div>
			</IonContent>
		</>
	);
};
export default WhyOtto;
