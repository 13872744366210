import fetch from 'unfetch';
/**
 *
 * @param {*} barId the id of the bar
 * @param {*} comment the comment body
 * @returns
 */
const addComment = async (barId, comment) => {
	const url = `/api/comments/send`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			comment: comment,
			barId: barId,
		}),
	});

	if (res.ok) {
		return { status: 'success', data: await res.json() };
	} else {
		console.log(await res.json());
		return { status: 'error', data: await res.json() };
	}
};
export default addComment;
