import fetch from 'unfetch';
import useSWR from 'swr';

const FetchMessageSWR = async (barId) => {
	const fetcher = (...args) =>
		fetch(...args, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify({ barId: barId }),
		}).then((res) =>
			res.json().then((data) => {
				return data;
			})
		);

	const { data: messages, error } = useSWR(`/api/comments/get`, fetcher, {
		refreshInterval: 4000,
	});
	return await messages;
};

export default FetchMessageSWR;
