import fetch from 'unfetch';

const verifyEmail = async (email, code) => {
	if(!email.endsWith('@wustl.edu')){
		email = email + '@wustl.edu';
	}
	console.log('EMAIL IS', email);
	console.log('code is', code);
	const url = `/api/email/verify`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			email: email,
			code: code,
		}),
	});

	if (res.ok) {
		console.log(await res.json());
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		let p = responseData?.data;
		localStorage.setItem('auth', responseData.jwt);
		localStorage.setItem('userData', JSON.stringify(p));
		return { status: 'success', data: await res.json() };
	} else {
		console.log(await res.json());
		return { status: 'error', data: await res.json() };
	}
};
export default verifyEmail;
