import fetch from 'unfetch';

const getLikes = async () => {
	const url = `/api/userlikes`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
	});

	if (res.ok) {
		return { status: 'success', data: await res.json() };
	} else {
		console.log(await res.json());
		return { status: 'error', data: await res.json() };
	}
};
export default getLikes;
