import fetch from 'unfetch';
import useSWR from 'swr';

const FetchLikesSWR = async () => {
	const fetcher = (...args) =>
		fetch(...args, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				authorization: localStorage.getItem('auth'),
			},
			body: JSON.stringify({}),
		}).then((res) =>
			res.json().then((data) => {
				return data;
			})
		);

	const { data: bars, error } = useSWR(`/api/fetchRelevantData`, fetcher, {
		refreshInterval: 40000,
		shouldRetryOnError: false,
	});
	return await bars;
};

export default FetchLikesSWR;
