import {
	IonSpinner,
	IonItem,
	IonLabel,
	IonSelectOption,
	IonSelect,
	IonToast,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import FooterCover from '../components/FooterCover';
import signup from '../data/signup/signup';
import { ImSpinner9 } from 'react-icons/im';
import { useHistory } from 'react-router';
const SignUpPage = () => {
	const [suStage, setSUStage] = useState(0);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [fn, setFN] = useState(null);
	const [ln, setLN] = useState(null);
	const [yr, setYr] = useState(null);
	const [suErrorMessage, setSuErrorMessage] = useState('');
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	let history = useHistory();
	let emailRef = useRef();
	let pwRef = useRef();
	let fnRef = useRef();
	let lnRef = useRef();
	let yrRef = useRef();
	// const submitSignup = async () => {
	// 	console.log(password);
	// 	signup(email, fn, ln, password).then((data) => {
	// 		console.log(data);
	// 	});
	// };
	const HandleSignup = async () => {
		setIsLoading(true);
		if (suStage === 0) {
			setEmail(emailRef.current.value);
			setIsLoading(false);
			setSUStage(suStage + 1);
		} else if (suStage === 1) {
			setLN(lnRef.current.value);
			setFN(fnRef.current.value);
			setIsLoading(false);
			if (yr !== null) {
				setSUStage(suStage + 1);
			} else {
				setIsLoading(false);
				setSuErrorMessage('Error — Please select a graduation year.');
				setShowErrorMessage(true);
			}
		} else if (suStage == 2) {
			setPassword(pwRef.current.value);
			signup(email, fn, ln, pwRef.current.value, yr).then((data) => {
				console.log(data?.data);
				if (data?.status === 'error') {
					let msg = data?.data?.message;
					setSuErrorMessage(msg);
					setShowErrorMessage(true);
					setIsLoading(false);
				} else {
					setIsLoading(false);
					history.push('/verify?email=' + email);
				}
			});
		}
		// } else if (suStage == 3) {
		// 	setPassword(pwRef.current.value);
		// 	console.log(yr);
		// 	signup(email, fn, ln, pwRef.current.value, yr).then(
		// 		(data) => {
		// 			console.log(data);
		// 			setIsLoading(false);
		// 			window.location = `/verify?${email}`;
		// 		}
		// 	);
		// }
	};
	useEffect(() => {
		console.log(email);
	}, [email]);
	useEffect(() => {
		console.log(password);
	}, [password]);
	useEffect(() => {
		console.log(yr);
	}, [yr]);
	return (
		<>
			<IonToast
				color="danger"
				isOpen={showErrorMessage}
				onDidDismiss={() => setShowErrorMessage(false)}
				message={suErrorMessage}
				position={'top'}
				duration={3000}
			/>
			<div className="flex flex-col bg-white h-screen">
				<div className="flex mt-2 z-50">
					{suStage === 0 ? (
						<span className="w-1/3 mx-2 border border-4 border-blue-400 bg-blue-500 rounded-md"></span>
					) : (
						<span className="w-1/3 mx-2 border border-4 border-gray-400 bg-gray-500 rounded-md"></span>
					)}
					{suStage === 1 ? (
						<span className="w-1/3 mx-2 border border-4 border-blue-400 bg-blue-500 rounded-md"></span>
					) : (
						<span className="w-1/3 mx-2 border border-4 border-gray-400 bg-gray-500 rounded-md"></span>
					)}
					{suStage === 2 ? (
						<span className="w-1/3 mx-2 border border-4 border-blue-400 bg-blue-500 rounded-md"></span>
					) : (
						<span className="w-1/3 mx-2 border border-4 border-gray-400 bg-gray-500 rounded-md"></span>
					)}
				</div>
				{suStage === 0 ? (
					<div className="w-full flex flex-col mx-auto">
						<div className="text-center mx-auto mt-24 text-3xl w-2/3 font-light text-gray-700">
							Please enter your WUSTL Email
							<div className="text-xs">
								(We won't share this with anyone besides your RA
								(kidding) )
							</div>
						</div>
						<div className="flex mt-12 bg-opacity-0 bg-gray-100 w-full">
							<div className="mx-auto flex w-2/3">
								<input
									ref={emailRef}
									defaultValue={email}
									className="w-1/2 lowercase mt-12 bg-opacity-0 bg-gray-100 border-b-2 border-red-800 border-opacity-40 px-4 py-2 outline-none text-gray-800"
									placeholder="Email"></input>{' '}
								<span className="w-1/2 lowercase mt-12 bg-opacity-0 bg-gray-100 border-b-2 border-red-800 border-opacity-40 px-4 py-2 outline-none text-gray-800">
									@wustl.edu
								</span>
							</div>
						</div>
					</div>
				) : null}
				{suStage === 1 ? (
					<div className="w-full flex flex-col mx-auto">
						<div className="text-center mx-auto mt-24 text-3xl w-2/3 font-light text-gray-700">
							Enter your First and Last Name:
							<div className="text-sm">
								(Your friends need this to find you!)
							</div>
						</div>
						<div className="flex flex-col mt-12 bg-opacity-0 bg-gray-100">
							<input
								ref={fnRef}
								defaultValue={fn}
								className="w-2/3 mx-auto bg-opacity-0 bg-gray-100 border-b-2 border-red-800 border-opacity-40 px-4 py-2 outline-none text-gray-800"
								placeholder="First Name"></input>
							<input
								ref={lnRef}
								defaultValue={ln}
								className="w-2/3 mt-4 mx-auto bg-opacity-0 bg-gray-100 border-b-2 border-red-800 border-opacity-40 px-4 py-2 outline-none text-gray-800"
								placeholder="Last Name"></input>
						</div>
						<IonItem>
							<IonLabel className="mt-12 w-2/3">
								Graduation Year
							</IonLabel>

							<IonSelect
								className="signup-select-color "
								value={yr}
								okText="Okay"
								cancelText="Dismiss"
								onIonChange={(e) => setYr(e.detail.value)}>
								<IonSelectOption value="2022">
									2022
								</IonSelectOption>
								<IonSelectOption value="2023">
									2023
								</IonSelectOption>
								<IonSelectOption value="2024">
									2024
								</IonSelectOption>
								<IonSelectOption value="2025">
									2025
								</IonSelectOption>
							</IonSelect>
						</IonItem>
						<div className="text-xs text-black text-center w-2/3 mx-auto mt-2">
							<div className="text-xs">
								You CANNOT change this later. Just helps you see
								how many people in your grade are going :).
							</div>
						</div>
					</div>
				) : null}
				{suStage === 2 ? (
					<div className="w-full flex flex-col mx-auto">
						<div className="text-center mx-auto mt-24 text-3xl w-2/3 font-light text-gray-700">
							Final Step! Enter your password:
						</div>
						<div className="flex mt-48 bg-opacity-0 bg-gray-100">
							<input
								ref={pwRef}
								defaultValue={password}
								className="w-2/3 mx-auto bg-opacity-0 bg-gray-100 border-b-2 border-red-800 border-opacity-40 px-4 py-2 outline-none text-gray-800"
								placeholder="Password"></input>
						</div>
					</div>
				) : null}
				<div className="flex">
					{!isLoading ? (
						<>
							{suStage > 0 ? (
								<div
									className="mx-auto text-2xl bg-blue-300 mt-24 rounded-lg py-2 px-4"
									onClick={async () => {
										if (suStage !== 0) {
											setSUStage(suStage - 1);
										}
									}}>
									Back
								</div>
							) : null}

							<div
								className="mx-auto text-2xl text-gray-800 opacity-100 bg-blue-400 mt-24 rounded-lg py-2 px-4"
								onClick={async () => {
									if (suStage !== 3) {
										HandleSignup();
									}
								}}>
								Next
							</div>
						</>
					) : (
						<div className="mx-auto mt-24">
							<ImSpinner9 className="animate-spin text-red-400 text-4xl" />
						</div>
					)}
				</div>
			</div>
			{/* <FooterCover></FooterCover> */}
		</>
	);
};
export default SignUpPage;
