import fetch from "unfetch";
/**
 *
 * @param {} username The USERNAME of the user you want to follow. Not the UUID
 * @returns whether or not the req went thru
 */
const sendFriendRequest = async (username) => {
	const url = `/api/friends/follow`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({ user_to_follow_id: username }),
	});

	if (res.ok) {
		return { status: "succ", data: await res.json() };
	} else {
		return { status: "error" };
	}
};
export default sendFriendRequest;
