import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonModal,
	IonButton,
	IonFooter,
	IonTab,
	IonRefresher,
	IonRefresherContent,
	IonToast,
} from '@ionic/react';

import getBars from '../data/getBars';
import { useEffect, useRef, useState, useMemo } from 'react';
import BarTag from '../components/BarTag';
import getLikes from '../data/getLikes';
import BarPopUp from '../components/BarPopUp';
import CommentModal from '../components/CommentModal';
import WhyOtto from '../components/WhyOtto';
import cow_img from '../components/images/cow.png';
import { useLocation } from 'react-router-dom';
import MoovePlain from '../components/images/MoovePlain.png';
const Tab1 = ({
	showActions,
	shouldShow,
	barData,
	setBarData,
	likes,
	setLikes,
	friends,
	setFriends,
	match,
	signedIn,
	setDidClick,
	didClick,
	setStatusColor,
	defaultStatusColor,
}) => {
	//const [likes, setLikes] = useState(null); //Depends on our settings. If they can like multiple bars, this will be an array of likes. If they can only like one, this is the like itself.
	const [barPopUp, setBarPopUp] = useState(false);
	const [barPData, setBarPData] = useState(null);
	const [currBar, setCurrBar] = useState(likes);
	const [showComments, setShowComments] = useState(false);
	const [currComment, setCurrComment] = useState(null);
	const [commentData, setCommentData] = useState(null);
	const [whyOtto, setWhyOtto] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isError, setIsError] = useState(false);
	function useQuery() {
		const { search } = useLocation();

		return useMemo(() => new URLSearchParams(search), [search]);
	}
	let query = useQuery();
	//
	useEffect(() => {
		console.log(barData);
		if (barData[0] !== undefined) {
			let url = document.URL;

			url = url.split('?');
			let paramBar = query.get('bar');
			console.log(paramBar);
			if (paramBar) {
				for (let i in barData) {
					console.log('CHECKING BAR ', i);

					if (barData[i].id?.toString() === paramBar) {
						console.log('MATCH ', barData[i]);
						setBarPData(barData[i]);
						setBarPopUp(true);
					}
				}

				// setBarPData(baraDa)
			}
		}
	}, []);
	// useEffect(() => {
	// 	if (currComment) {
	// 		getComments(currComment).then((data) => {

	// 			// setCommentData()
	// 		});
	// 	}
	// }, [currComment]);

	const updateLikeCount = async (id, upOrDown) => {
		let nObj = barData;

		for (let i in nObj) {
			if (nObj[i]?.id === id) {
				if (upOrDown) {
					nObj[i].voted_by = nObj[i].voted_by + 1;
				} else {
					nObj[i].voted_by = nObj[i].voted_by - 1;
				}
			}
		}
		setTimeout(() => {
			nObj.sort((a, b) => (a.voted_by <= b.voted_by ? 1 : -1));
			setBarData(nObj);
		}, 2000);
	};
	useEffect(async () => {
		// verifyUser().then((data) => {
		// 	if (data.status === 'error') {
		// 		window.location = '/';
		// 	}
		// });
		// getBars().then((data) => {
		// 	console.log(data);
		// 	// console.log(data?.data?.data);
		// 	data?.data?.data.sort((a, b) =>
		// 		a.voted_by <= b.voted_by ? 1 : -1
		// 	);
		// 	setBarData(data?.data?.data);
		// });
		// getLikes().then((data) => {
		// 	// console.log("User like data", data?.data?.data)
		// 	setLikes(data?.data?.data);
		// });
		// getFriendInfo('friends').then((data) => {
		// 	setFriends(data?.data?.data);
		// });
	}, []);

	useEffect(() => {
		getBars().then((data) => {
			// console.log(data?.data?.data);
			setBarData(data?.data?.data);
			setDidClick(false);
		});
		getLikes().then((data) => {
			setLikes(data?.data?.data);
		});
	}, [didClick]);

	let pr = useRef(null);
	const refreshAllData = async () => {
		getBars().then((data) => {
			data?.data?.data.sort((a, b) =>
				a.voted_by <= b.voted_by ? 1 : -1
			);
			setBarData(data?.data?.data);
			setFriends(data?.data?.friends);
		});
		getLikes().then((data) => {
			setLikes(data?.data?.data);
		});

		return;
	};
	useEffect(() => {}, [barPopUp]);
	useEffect(() => {}, [currBar]);
	useEffect(() => {}, [barData]);
	useEffect(() => {}, [friends]);

	const doRefresh = async (event) => {
		let d = await getBars();
		setFriends(d?.data?.friends);
		setBarData(d?.data?.data);
		getLikes().then((data) => {
			setLikes(data?.data?.data);
			event.detail.complete();
		});
	};
	const themeColor = '#7661B2';

	return (
		// <IonPage color="contrast">
		<>
			<IonContent
				hidden={shouldShow}
				scrollY={true}
				color={'tertiary'}
				fullscreen={true}>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent
						cssClass=".refresher-refreshing-icon"
						refreshingSpinner={'crescent'}></IonRefresherContent>
					{/* // pullingIcon={chevronDownCircleOutline}
						// pullingText="Pull to refresh"
						// refreshingSpinner="circles"
						// refreshingText="Refreshing..." */}
				</IonRefresher>
				<IonToast
					isOpen={isError}
					onDidDismiss={() => setIsError(false)}
					message={errorMessage}
					position={'top'}
					duration={3000}
				/>
				{!signedIn ? (
					<div className="fixed top-10 bg-white shadow-sm shadow-gray-700 w-full h-14 flex bg-opacity-90 z-50 rounded-md text-gray-500">
						<div className="text-center w-2/3 mx-auto font-bold">
							Please{' '}
							<span
								onClick={() => {
									window.location = '/login';
								}}
								className="text-xl underline">
								Login
							</span>{' '}
							or{' '}
							<span
								onClick={() => {
									window.location = '/signup';
								}}
								className="text-xl underline">
								Sign Up
							</span>{' '}
							<br></br>
							<span className="text-xs font-bold">
								to vote and see your friends attending!
							</span>
						</div>
					</div>
				) : null}
				{/* <IonContent> */}

				{/* </IonContent> */}

				{barData ? (
					<div className="w-full pb-12 pt-12 bg-moove-blue" ref={pr}>
						{/* <div className="text-center text-white font-medium w-4/5 text-3xl mx-auto border border-white rounded-lg shadow-xl bg-gradient-to-r from-cyan-500 to-blue-500 flex">
							<div className="mx-auto">
								<em>Thursday</em> Moove{' '}
							</div>

							<img
								src={cow_img}
								className="w-8 h-8 my-auto mx-auto"></img>
						</div> */}
						<div className="text-center flex w-full font-pacifico">
							<div className="mx-auto">
								<span className="font-sans text-lg">
									Vote for the{' '}
								</span>
								<span className="font-bold text-xl">
									Thursday Moove
								</span>
							</div>
						</div>
						<div className="text-center text-white font-light text-xs">
							Tap on a bar to see details.
						</div>

						<IonModal
							isOpen={whyOtto}
							scrollY={true}
							swipeToClose={true}
							presentingElement={pr.current}
							onDidDismiss={() => setWhyOtto(false)}>
							<WhyOtto setWhyOtto={setWhyOtto}></WhyOtto>
						</IonModal>
						<IonModal
							isOpen={barPopUp}
							cssClass="my-custom-class"
							swipeToClose={true}
							// presentingElement={pr.current}
							onDidDismiss={() => {
								setStatusColor(defaultStatusColor);
								setBarPopUp(false);
							}}>
							<BarPopUp
								setStatusColor={setStatusColor}
								signedIn={signedIn}
								barData={barPData}
								setBarPopUp={setBarPopUp}
								friends={friends}></BarPopUp>
						</IonModal>
						<IonModal
							isOpen={showComments}
							cssClass="my-custom-class"
							// swipeToClose={true}
							// presentingElement={pr.current}
							onDidDismiss={() => {
								setStatusColor(defaultStatusColor);
								setCommentData(null);
								setShowComments(false);
							}}>
							<CommentModal
								setStatusColor={setStatusColor}
								setCurrComment={setCurrComment}
								setCommentData={setCommentData}
								commentData={commentData}
								currComment={currComment}
								setShowComments={
									setShowComments
								}></CommentModal>
						</IonModal>

						{barData?.map((id, idx) => {
							return (
								<BarTag
									key={idx}
									setErrorMessage={setErrorMessage}
									setIsError={setIsError}
									signedIn={signedIn}
									image={id?.image}
									setBarPData={setBarPData}
									setBarPopUp={setBarPopUp}
									friends={friends}
									setCurrComment={setCurrComment}
									setShowComments={setShowComments}
									friendsAttending={id?.friendsInAttendance}
									// isLiked={isLiked}
									updateLikeCount={updateLikeCount}
									setCurrBar={setCurrBar}
									currBar={currBar}
									setIsLiked={setLikes}
									isLiked={likes}
									setLikes={setLikes}
									name={id.name}
									likes={id.voted_by}
									website={id?.website}
									id={id?.id}
									setDidClick={setDidClick}></BarTag>
							);
						})}
						<div
							onClick={() => {
								setWhyOtto(true);
							}}
							className="text-center text-white font-medium pt-2 font-light underline">
							Why use this app?
						</div>
						<div className="text-center text-white font-medium pt-2 font-light underline">
							<a
								href="https://forms.gle/D5Pwv6amKTkHGbct6"
								target="_blank"
								rel="noopener noreferrer">
								Submit feedback
							</a>
						</div>
					</div>
				) : null}
			</IonContent>
		</>
		// 	{/* <Footer></Footer> */}
		// {/* </IonPage> */}
	);
};

export default Tab1;
