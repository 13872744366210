import { IonContent, IonHeader, IonInput, IonToast } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineLoading, AiOutlineSend } from 'react-icons/ai';
import getComments from '../data/comments/getComments';
import addComment from '../data/comments/addComment';
import ScrollToBottom from 'react-scroll-to-bottom';
import FetchMessageSWR from '../data/swr/getMessagesSWR';
import { ImSpinner } from 'react-icons/im';
import { CgSpinnerAlt } from 'react-icons/cg';
import { MdOutlinePersonOutline } from 'react-icons/md';

const CommentModal = ({
	setCurrComment,
	currComment,
	setShowComments,
	commentData,
	setStatusColor,
}) => {
	const [commData, setCommData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [commError, setCommError] = useState(false);
	const [commErrorMessage, setCommErrorMessage] =
		useState('Too many messages!');
	const colors = [
		'bg-red-400',
		'bg-blue-400',
		'bg-indigo-400',
		'bg-yellow-500',
		'bg-green-400',
		'bg-fuchsia-400',
		'bg-rose-400',
	];
	let commentRef = useRef();
	let cData = FetchMessageSWR(currComment);
	useEffect(() => {
		setStatusColor('#FFFFFF');
		setCommData(null);
		getComments(currComment).then((data) => {
			setCommData(data?.data?.data);
			setIsLoading(false);
		});
	}, []);
	useEffect(() => {
		if (currComment) {
			cData.then((data) => {
				if (data?.data) {
					setCommData(data?.data);
				}
			});
		}
	}, [cData]);
	let messagesEndRef = useRef(null);
	// useEffect(() => {
	// 	// var list = document.getElementById('list');
	// 	// list.scrollToBottom();
	// }, [commData]);
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	const updateCommData = async (newData) => {
		await setCommData(newData);
	};
	return (
		<>
			<IonHeader className="top-0 relative bg-white pb-2 px-4">
				<div className="w-11/12 text-blue-400 absolute items-end place-content-end z-50 text-right">
					<span
						className="bg-white rounded-lg right items-end py-2 mt-0 items-end border border-blue-300 px-6 py-2"
						onClick={() => {
							setCurrComment(null);
							setCommData(null);
							setShowComments(false);
						}}>
						Close
					</span>
				</div>
				<div className="text-gray-500 font-light text-xs w-3/5 ml-4 mt-2">
					Messages, like the horrible course eval you left your
					college writing professor, are completely anonymous. Be
					nice.{' '}
				</div>
			</IonHeader>
			<IonToast
				color="danger"
				isOpen={commError}
				onDidDismiss={() => setCommError(false)}
				message={commErrorMessage}
				position={'top'}
				duration={3000}
			/>
			<IonContent fullscreen={true} scrollY={false}>
				<div className="px-4 w-full flex-col flex h-full absolute">
					<ScrollToBottom
						className="w-full mx-auto h-4/5 overflow-y-scroll flex flex-col mt-2 pb-2 relative bottom-0"
						id="list">
						{commData !== null && commData[0] !== undefined ? (
							<>
								{!isLoading ? (
									<>
										{commData
											?.slice(0)
											.reverse()
											.map((data, id) => {
												let min = Math.ceil(0);
												let max = Math.floor(6);
												let num =
													Math.floor(
														Math.random() *
															(max - min + 1)
													) + min;
												return (
													<div
														key={id}
														className="flex mt-4">
														<div
															className={`flex w-6 h-6 ${colors[num]} rounded-full my-auto mx-2`}>
															<MdOutlinePersonOutline className="text-black my-auto text-2xl" />
														</div>
														<span className="text-black bg-gray-300 px-6 py-2 text-left rounded-br-full rounded-tr-full rounded-tl-full text-xs">
															{data?.body}
														</span>
													</div>
												);
											})}
										<div ref={messagesEndRef} />
									</>
								) : (
									<div className="w-full h-full flex flex-col">
										<div className="mx-auto my-auto text-black font-light text-center">
											Loading...
											<CgSpinnerAlt className="text-blue-400 animate-spin my-auto mx-auto text-8xl duration-1000"></CgSpinnerAlt>
										</div>
									</div>
								)}
							</>
						) : (
							<div className="w-full h-full flex">
								<div className="mx-auto my-auto font-light text-gray-800">
									No Messages To Display.
								</div>
							</div>
						)}
					</ScrollToBottom>
				</div>
			</IonContent>
			<div className="flex relative w-11/12 mx-auto pt-2 px-2 mt-2">
				<input
					ref={commentRef}
					position="fixed"
					className="bg-gray-300 text-gray-500 rounded-xl mx-auto w-4/5 outline-none py-2 px-2"
					placeholder={'Type your comment here...'}></input>
				<AiOutlineSend
					className="mx-auto my-auto text-2xl text-blue-400 hover:text-blue-600 "
					onClick={() => {
						let t = commentRef.current.value;
						commentRef.current.value = '';
						let b = [];

						b.push(commData);
						let newC = {
							body: t,
						};
						b[0].unshift(newC);
						updateCommData(b[0]);
						addComment(currComment, t).then((data) => {
							console.log(data.status);
							if (data?.status === 'error') {
								setCommError(data?.data?.error.toString());
								setCommError(true);
							}
							getComments(currComment).then((data) => {
								setCommData(data?.data?.data);
							});
						});
					}}></AiOutlineSend>
			</div>
		</>
	);
};
export default CommentModal;
