import { Redirect, Route } from 'react-router-dom';
import {
	IonApp,
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
	setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, home, people, square, triangle } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import VerifyPage from './pages/verifyEmail';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import './input.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import SignUpPage from './pages/signup';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './AppCSS.css';
/* Theme variables */
import './theme/variables.css';
import LoginPage from './pages/LoginPage.js';
import LandingPage from './pages/LandingPage.js';
import FriendsPage from './pages/FriendsPage';
import {useEffect, useState} from 'react'
import MainPage from './pages/MainPage';
setupIonicReact();
const App: React.FC = () => (
	<IonApp>
		<IonReactRouter>
			{/* <IonTabs> */}
				<IonRouterOutlet>
					<Route exact path="/signup">
						<SignUpPage />
					</Route>
					<Route exact path="/login">
						<LoginPage />
					</Route>
					<Route exact path="/welcome">
						<LandingPage />
					</Route>
					{/* <Route exact path="/friends">
						<FriendsPage />
					</Route> */}
					<Route exact path="/home">
						<MainPage />
					</Route>

					<Route exact path="/">
						<Redirect to="/welcome" />
					</Route>
					<Route path="/verify">
						<VerifyPage />
					</Route>
				</IonRouterOutlet>
				{/* <IonTabBar slot="bottom" > */}
					{/* <IonTabButton tab="Home" href="/home">
						<IonIcon icon={home} />
						<IonLabel>Home</IonLabel>
					</IonTabButton> 
					<IonTabButton tab="Friends" href="/friends">
						<IonIcon icon={people} />
						<IonLabel>Friends</IonLabel>
					</IonTabButton> */}
					{/* <IonTabButton tab="tab3" href="/tab3">
						<IonIcon icon={square} />
						<IonLabel>Tab 3</IonLabel>
					</IonTabButton> */}
				{/* </IonTabBar> */}
			{/* </IonTabs> */}
		</IonReactRouter>
	</IonApp>
);

export default App;
