import { IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import verifyUser from '../data/auth/verifyUser';
import getBars from '../data/getBars';
import BarTag from '../components/BarTag';
import { AiFillHeart } from 'react-icons/ai';
import BackgroundAnimation from '../components/BackgroundAnim';
import moove from '../components/images/moove.png';

const LandingPage = () => {
	const [barData, setBarData] = useState([]);
	const [barPData, setBarPData] = useState(null);
	const [barPopUp, setBarPopUp] = useState(false);
	const [didClick, setDidClick] = useState(false);
	const [likes, setLikes] = useState(null); //Depends on our settings. If they can like multiple bars, this will be an array of likes. If they can only like one, this is the like itself.
	const [friends, setFriends] = useState(null);
	const [currBar, setCurrBar] = useState(null);

	const updateLikeCount = async (id, upOrDown) => {
		let nObj = barData;

		for (let i in nObj) {
			if (nObj[i]?.id === id) {
				if (upOrDown) {
					nObj[i].voted_by.push('This is a sample random string');
				} else {
					nObj[i].voted_by.pop();
				}
			}
		}

		nObj.sort((a, b) => (a.voted_by?.length <= b.voted_by ? 1 : -1));
		setBarData(nObj);
	};

	useEffect(() => {
		verifyUser().then((data) => {
			console.log(data);
			if (data?.status !== 'error') {
				window.location = '/home';
			}
		});
	}, []);
	useEffect(async () => {
		getBars().then((data) => {
			// console.log(data?.data?.data);
			data?.data?.data.sort((a, b) =>
				a.voted_by <= b.voted_by?.length ? 1 : -1
			);
			setBarData(data?.data?.data);
		});
	}, []);
	return (
		<IonPage className="">
			<IonContent scrollY={false}>
				<div className="absolute bg-white bg-opacity-0 flex flex-col h-screen w-full z-50 opacity-100 ">
					<div className="bg-gray-600  rounded-xl flex flex-col w-4/5 h-1/2 my-auto mx-auto bg-opacity-98 shadow-xl shadow-gray-800">
						<div className="mx-auto w-full text-gray-200 text-6xl opacity-100 bg-blue-400 rounded-t-xl font-light py-4 px-4 text-center">
							<div className="w-full flex">
								<span
									onClick={() => {
										window.location = '/home';
									}}
									className="text-sm">
									X
								</span>
								<img
									src={moove}
									className="w-1/2 mx-auto"></img>
							</div>
							<div className="text-sm">
								Go where your friends are! <br></br> Made
								specifically for the WashU family :){' '}
							</div>
						</div>

						<div className=" text-gray-200 font-sans font-light border-opacity-20 my-auto flex flex-col rounded-sm w-full mx-auto py-8 px-8 shadow-opacity-10">
							<button
								className="bg-gradient-to-r from-cyan-500 to-blue-500 mt-2 text-xl w-full bg-blue-500 mx-auto rounded-lg py-2 px-6 opacity-100"
								onClick={() => {
									window.location = '/login';
								}}>
								Login
							</button>
							<button
								className="bg-gradient-to-r from-cyan-500 to-blue-500 mt-12 text-xl bg-blue-500 w-full mx-auto rounded-lg py-2 px-6 opacity-100"
								onClick={() => {
									window.location = '/signup';
								}}>
								Sign Up
							</button>
						</div>
					</div>
				</div>
				<div className="absolute w-full h-screen z-10 opacity-90 bg-black">
					<div className="z-10 opacity-80">
						{barData?.map((id, idx) => {
							return (
								<div className="w-full flex mt-6 flex-col z-20 ">
									<div className=" w-5/6 h-1/4 mx-auto bg-gray-600 rounded-lg shadow-xl text-gray-800 border-2 border-blue-200 shadow duration-500 pointer shadow-blue-300 text-center flex font-medium text-xl ">
										{/* <img className="object-cover rounded-lg blur-sm" src={image}></img> */}
										<div className="w-full flex mx-auto z-10 ">
											<div className="flex pl-4 py-1 flex-col w-full text-left z-50 text-white rounded-lg">
												<div className="font-bold bg-gray-500 rounded-lg text-center w-11/12 mt-2 py-1 blur-sm">
													{id?.name}
												</div>
												<div className=" font-light pt-2 pb-2 flex flex-col blur-sm">
													<span>
														Likes:{' '}
														<span className="bg-gray-700 rounded-lg px-2 py-1">
															0{' '}
														</span>
													</span>
													<span className=" text-md blur-sm">
														Friends Attending:{' '}
														<span className="bg-gray-700 rounded-lg px-2 py-1">
															0
														</span>
													</span>
												</div>
											</div>
											<AiFillHeart
												className={
													'blur-sm text-gray-400 my-auto pr-4 pr-4 text-6xl animate-pulse duration-2000'
												}></AiFillHeart>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				{/* <div className="w-full h-2/3 absolute bottom-0 bg-red-400 z-50"></div> */}
			</IonContent>
		</IonPage>
	);
};
export default LandingPage;
