import fetch from 'unfetch';
/**
 *
 * @param {*} request_type Can be "pending_requests", "issued_requests", "friends"
 * @returns  The requested info
 */
const getFriendInfo = async (request_type) => {
	const url = `/api/friends/information`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ request_type: request_type }),
	});

	if (res.ok) {
		return { status: 'succ', data: await res.json() };
	} else {
		return { status: 'error' };
	}
};
export default getFriendInfo;
