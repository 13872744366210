import {
	AiOutlineArrowDown,
	AiOutlineArrowUp,
	AiFillCheckSquare,
	AiOutlineHeart,
	AiFillHeart,
} from 'react-icons/ai';
import vote from '../data/vote';
import { useEffect, useState } from 'react';
import { heartCircleOutline } from 'ionicons/icons';
import { useDoubleTap } from 'use-double-tap';
import BarPopUp from './BarPopUp';
import { BiCommentDots } from 'react-icons/bi';
import getGradeDist from '../data/getGradeDist';
import { Doughnut } from 'react-chartjs-2';
import { GiBigEgg, GiChicken } from 'react-icons/gi';
let lastPress = 0;
let timer;
const BarTag = ({
	name,
	likes,
	website,
	id,
	friendsAttending,
	setDidClick,
	isLiked,
	setIsLiked,
	setBarPopUp,
	setBarPData,
	image,
	setLikes,
	currBar,
	setCurrBar,
	updateLikeCount,
	friends,
	setShowComments,
	setCurrComment,
	signedIn,
	setIsError,
	setErrorMessage,
}) => {
	const [firstClick, setFirstClick] = useState(0);
	const [clickTime, setClickTime] = useState(0);
	const [liked, setLiked] = useState(isLiked);
	const [numLikes, setNumLikes] = useState(likes);
	const [numFriendsAttending, setNumFriendsAttending] = useState(0);
	const [distData, setDistData] = useState(null);
	const [freshDist, setFreshDist] = useState(0);
	const [sophDist, setSophDist] = useState(0);
	const [junDist, setJunDist] = useState(0);
	const [senDist, setSenDist] = useState(0);
	// useEffect(()=>{
	// 	for(let i in likes){
	// 		for(let j in friends){
	// 			if(likes[i] === friends[j].email){
	// 				console.log("MATCHED ", likes[i], friends[i])
	// 				setNumFriendsAttending(numFriendsAttending+1)
	// 			}
	// 		}

	// 	}
	// }, [friends, likes])
	// useEffect(() => {
	// 	for (let i in listOfLikes) {
	// 		if (listOfLikes[i] === id.toString()) {
	// 			setIsLiked(true);
	// 		}
	// 	}
	// }, []);

	// console.log(id)
	// console.log(isLiked)
	useEffect(() => {
		getGradeDist(id).then((data) => {
			setDistData(data?.data);
		});
	}, []);
	const countFriends = () => {
		let c = 0;
		for (let i in friends) {
			for (let j in likes) {
				if (likes[j] === friends[i]?.email) {
					c++;
				}
			}
		}
		return c;
	};
	// const shouldAnimate = ()=>{
	// 	if(count = 0){return ("hover:animate-ping")

	// }else {return ""}
	// }
	let bObj = {};
	bObj.name = name;
	bObj.likes = likes;
	bObj.webste = website;
	bObj.image = image;
	bObj.freshDist = freshDist;
	bObj.sophDist = sophDist;
	bObj.junDist = junDist;
	bObj.senDist = senDist;
	bObj.id = id;
	bObj.friendsInAttendance = friendsAttending;
	let count = 0;

	useEffect(() => {
		if (distData) {
			let gradeCounts = {
				twentyTwo: 0,
				twentyThree: 0,
				twentyFour: 0,
				twentyFive: 0,
				other: 0,
			};

			for (let i in distData) {
				if (distData[i].yr === '2022') {
					gradeCounts.twentyTwo = gradeCounts.twentyTwo + 1;
				} else if (distData[i].yr === '2023') {
					gradeCounts.twentyThree = gradeCounts.twentyThree + 1;
				} else if (distData[i].yr === '2024') {
					gradeCounts.twentyFour = gradeCounts.twentyFour + 1;
				} else if (distData[i].yr === '2025') {
					gradeCounts.twentyFive = gradeCounts.twentyFive + 1;
				} else {
					gradeCounts.other = gradeCounts.other + 1;
				}
			}
			setSenDist((gradeCounts.twentyTwo / distData.length) * 100);
			setJunDist((gradeCounts.twentyThree / distData.length) * 100);
			setSophDist((gradeCounts.twentyFour / distData.length) * 100);
			setFreshDist((gradeCounts.twentyFive / distData.length) * 100);
		}
	}, [distData]);

	let min = Math.ceil(0);
	let max = Math.floor(6);
	let max2 = Math.floor(friends?.length);
	let num = Math.floor(Math.random() * (max - min + 1)) + min;
	let num2 = Math.floor(Math.random() * (max2 - min + 1)) + min;

	const colors = ['blue', 'orange', 'amber', 'yellow', 'emerald'];
	return (
		<div className="w-full flex mt-6 flex-col z-20">
			{/* bg-gradient-to-r from-gray-900 to-gray-900 */}

			<div
				className={`w-5/6 border border-white border-opacity-30 bg-opacity-0 bg-gray-900 h-1/4 mx-auto relative backdrop-blur-3xl rounded-lg text-gray-800 duration-500 pointer  text-center flex font-medium text-xl`}>
				{/* <div className="w-full h-full absolute top-0 bottom-0 left-0 bg-gradient-to-r from-gray-100 to-gray-300 rounded-lg bg-opacity-90"></div> */}
				<img
					className=" bg-red-400 border border-blue-400 relative rounded-lg h-48 w-full"
					src={image}
					onClick={() => {
						setBarPData(bObj);
						setBarPopUp(true);
					}}></img>
				<div className="w-full flex mx-auto z-10 bottom-0 absolute bg-white rounded-lg">
					<div
						className="flex pl-4 py-1 flex-col w-full text-left z-50 text-white rounded-lg"
						onClick={() => {
							setBarPData(bObj);
							setBarPopUp(true);
						}}>
						<div className="font-bold text-gray-600 rounded-lg text-left w-11/12 mt-2 py-1">
							{name}
						</div>
						<div className=" font-light pt-2 pb-2 flex flex-rows text-black text-sm text-gray-600">
							<span className="">
								{''}
								{' ' + likes} people going,{' '}
								{signedIn ? (
									<span>
										{friendsAttending?.length} friends.
									</span>
								) : (
									<span className="text-xs text-white bg-gray-700 rounded-lg mx-auto my-auto px-2 py-1">
										Sign in to see!
									</span>
								)}
							</span>

							<div className="flex my-auto text-lg">
								{senDist + junDist < 70 && (
									<GiBigEgg></GiBigEgg>
								)}
								{freshDist + sophDist < 70 && (
									<GiChicken></GiChicken>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col text-center">
						<AiFillHeart
							onClick={async () => {
								// setLiked(!liked)
								if (signedIn) {
									if (id !== currBar) {
										setLikes(id);
										updateLikeCount(currBar, false);
										setCurrBar(id);
										updateLikeCount(id, true);
										vote(id, isLiked).then((data) => {
											setDidClick(true);
										});
									} else {
										updateLikeCount(currBar, false).then(
											(data) => {
												setLikes(null);
												setCurrBar(null);
												// updateLikeCount(id, true);
												setIsLiked(null);
												vote(id, false).then((data) => {
													setDidClick(true);
												});
											}
										);
									}
								} else {
									setErrorMessage(
										'Please sign in / up to vote!'
									);
									setIsError(true);
									window.location = '/welcome';
								}
							}}
							className={
								id === currBar
									? `text-red-500 my-auto mx-auto text-5xl mr-2`
									: 'text-gray-900 my-auto mx-auto text-5xl mr-2'
							}></AiFillHeart>
						<BiCommentDots
							onClick={() => {
								setCurrComment(id);
								setShowComments(true);
							}}
							className="my-auto text-xl text-black z-50 mx-auto"></BiCommentDots>
					</div>
				</div>
			</div>
			{/* <div
				className="flex w-1/3 mx-auto bg-blue-300 rounded-lg shadow-xl shadow-gray-100 py-2 px-4 mt-4"
				onClick={() => {
					console.log('VOTING FOR ', name, ' ID IS ', id);

					vote(id, !isLiked).then((data) => {
						console.log(data);
						setDidClick(true);
					});
				}}> */}

			{/* <AiFillCheckSquare className="mx-auto my-auto font-bold text-2xl text-green-900"></AiFillCheckSquare> */}
			{/* <div className="text-sm mx-auto">I'm going!</div> */}
			{/* </div> */}
			{/* Old method for showing different grades going: <div
				style={{
					width: 40 + '%',
					display: 'flex',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}>
				<div style={styling2022}></div>
				<div style={styling2025}></div>
				<div style={styling2024}></div>
				<div style={styling2023}></div>
			</div> */}
		</div>
	);
};
export default BarTag;
