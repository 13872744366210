import { useRef, useState } from 'react';

const ShareBar = ({ name, id, setShowShareBar }) => {
	let urlRef = useRef(null);
	const [copied, setCopied] = useState(false);
	return (
		<>
			<div className="w-full h-screen absolute flex z-50">
				<div className="my-auto mx-auto w-3/4 h-1/4 bg-gray-400 rounded-lg flex flex-col text-center ">
					<div
						className="text-left w-2/3 px-4 py-2"
						onClick={() => {
							setShowShareBar(false);
						}}>
						X
					</div>
					<div className="w-3/4 font-medium text-xl mx-auto mt-4">
						Tap to copy the link!
					</div>
					<input
						className="w-3/4 rounded-lg bg-gray-200 mx-auto mt-4 px-2"
						value={'https://www.the-moove.com/home?bar=' + id}
						ref={urlRef}></input>
					<button
						className="w-2/3 rounded-lg bg-blue-400 mx-auto mt-8 py-2 border border-white"
						onClick={() => {
							navigator.clipboard.writeText(
								urlRef?.current?.value
							);
							setCopied(true);
						}}>
						{copied ? 'Copy!' : 'Copied'}
					</button>
				</div>
			</div>
		</>
	);
};
export default ShareBar;
