import unfriend from '../../data/friends/unfriend';
import getFriendInfo from '../../data/friends/getFriendInfo';
const FriendPopUp = ({
	setIsLoadingReq,
	setShowExistFriendModal,
	friendModalData,
	isLoadingReq,
	setExistFriend,
}) => {
	return (
		<div className="w-full h-screen absolute flex z-50">
			<div className="w-3/4 h-1/3 mx-auto mt-28 bg-gray-300 rounded-lg flex flex-col shadow-2xl shadow-black">
				<span
					className="mt-2 ml-4 text-lg"
					onClick={() => {
						setIsLoadingReq(false);
						setShowExistFriendModal(false);
					}}>
					X
				</span>
				<div className="mx-auto mt-4 text-2xl">
					{friendModalData?.firstName} {friendModalData?.lastName}
				</div>

				<div className="mx-auto text-sm mt-4 font-light">
					{friendModalData?.email}
				</div>
				<span className="text-center font-light text-sm py-4">
					(Neither you nor your friend will be able to see your vote
					anymore)
				</span>
				{!isLoadingReq ? (
					<>
						<button
							className="mt-4 w-2/3 mx-auto bg-blue-400 rounded-lg p-2 hover:bg-blue-500"
							onClick={() => {
								unfriend(friendModalData?.id).then((data) => {
									getFriendInfo('friends').then((data) => {
										setExistFriend(data?.data?.data);
									});
								});
								setIsLoadingReq(true);
							}}>
							UnFriend.
						</button>
					</>
				) : (
					<button
						className="mt-4 w-2/3 mx-auto bg-blue-400 rounded-lg p-2"
						onClick={() => {}}>
						Un-Friended
					</button>
				)}
			</div>
		</div>
	);
};
export default FriendPopUp;
