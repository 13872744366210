import { IonSpinner } from '@ionic/react';
import { useEffect, useState, useRef } from 'react';
import { Route } from 'workbox-routing';
import verifyEmail from '../data/verifyEmail';
import { useLocation } from 'react-router-dom';
import React from 'react';
const VerifyPage = ({}) => {
	console.log('made it');
	const [email, setEmail] = useState(null);
	const [code, setCode] = useState(null);
	// let splitCode = document.URL.split('verify/');
	// let splitTwo = splitCode[1]?.split('?');
	// function useQuery() {
	// 	const { search } = useLocation();

	// 	return React.useMemo(() => new URLSearchParams(search), [search]);
	//   }

	let emailRef = useRef();
	let codeRef = useRef();
	// let query = useQuery();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		setEmail(urlParams.get('email'));
	}, []);

	const verifyFun = async () => {
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('email') && urlParams.get('code')) {
			verifyEmail(urlParams.get('email'), urlParams.get('code')).then((data) => {
				if (data.status !== 'error') {
					setCode('');
					window.location = '/';
				}
			});
		} else if (email) {
			verifyEmail(email, codeRef?.current?.value).then((data) => {
				if (data.status !== 'error') {
					setCode('');
					window.location = '/';
				}
			});
		}
		 else {
			verifyEmail(emailRef?.current?.value, codeRef?.current?.value).then(
				(data) => {
					if (data.status !== 'error') {
						setEmail('');
						setCode('');
						window.location = '/';
					}
				}
			);
		}
	};
	verifyFun();
	// return email && code ? (
	// 	<div className="text-green-400 flex flex-col w-full h-screen">
	// 		<div className="mx-auto mt-48 font-bold">
	// 			Success! Your account is verified!
	// 		</div>
	// 		<button
	// 			className="bg-blue-600 mt-12 animate-pulse rounded-lg p-2 text-white w-1/3 mx-auto"
	// 			onClick={() => {
	// 				window.location = '/';
	// 			}}>
	// 			Start Voting!
	// 		</button>
	// 	</div>
	// ) : (
		return <div className="w-full flex h-screen">
			<div className="mx-auto mt-48 flex flex-col text-center">
				<div className="text-blue-400 text-4xl mx-auto">
					<p>Waiting for email confirmation.</p>
					<p className="text-sm font-light">
						{' '}
						Psst. Check your junk mail.
					</p>
				</div>
				<IonSpinner className="mx-auto mt-6"></IonSpinner>
				<div>
					{!email ? (
						<input
							ref={emailRef}
							className="w-3/4 text-blue-500 border-b border-blue-900 border-opacity-60 bg-opacity-0 bg-gray-100 mt-8 outline-none"
							placeholder="Enter your email."></input>
					) : null}
				</div>
				<div>
					<input
						ref={codeRef}
						className="w-3/4 text-blue-500 border-b border-blue-900 border-opacity-60 bg-opacity-0 bg-gray-100 mt-8 outline-none"
						placeholder="Enter your code."></input>
				</div>
				<button
					className="w-1/3 mx-auto bg-green-400 rounded-lg py-2 px-4 mt-4 "
					onClick={() => {
						verifyFun();
					}}>
					Submit
				</button>
			</div>
		</div>
};
export default VerifyPage;
