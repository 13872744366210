import login from '../login';
const reAuthorize = async () => {
	if (localStorage.getItem('userData')) {
		let login_data = JSON.parse(localStorage.getItem('userData'));
		if (login_data?.email && login_data?.password) {
			let resStatus = await login(
				login_data?.email,
				login_data?.password
			);
			if (resStatus.status === 'error') {
				return { status: 'error' };
			} else {
				return { status: 'success' };
			}
		}
	} else {
		return { status: 'error' };
	}
};
export default reAuthorize;
