import fetch from 'unfetch';
/**
 *
 * @param {*} barId the id of the bar to vote for
 * @param {*} decision  true to vote for it false to dislike it
 * @returns
 */
const vote = async (barId, decision) => {
	console.log(barId);
	const url = `/api/bars/like`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			barId: barId,
			decision: decision,
		}),
	});

	if (res.ok) {
		console.log(await res.json());
		return { status: 'success', data: await res.json() };
	} else {
		console.log(await res.json());
		return { status: 'error', data: await res.json() };
	}
};
export default vote;
